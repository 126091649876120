import { ASCubeQuery } from 'utils/common/types';
import { getCookie, transformCubeQuery } from './utils';
import { APIMethod, ASCubeParams, CubeRequestOptions } from '../types';
import { CUBE_API_URL } from 'utils/api/urls';
import { buildCubeQuery } from './queries';
import { CubeService } from '../cubeService';
import { LoadMethodOptions, ResultSet } from '@cubejs-client/core';

class CubeRequest {
    private query: ASCubeQuery = {};
    // CubeJSApi Instance options
    private options: CubeRequestOptions = {
        apiUrl: CUBE_API_URL,
        pollInterval: 60,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: undefined
    };
    // Load method specific options i.e, subscribe, progressCallback. See CubeService comments
    // for more information.
    private loadOptions: LoadMethodOptions | undefined = undefined;

    constructor(params: ASCubeParams, loadOptions?: LoadMethodOptions, method: APIMethod = 'GET') {
        this.options.method = method;
        this.loadOptions = loadOptions;
        // Convert the raw parameters we've received into the correct Cube format.
        this.query = buildCubeQuery(params);

        // Implemented due to a limitation of the CubeJS API. If the query is too large, we need to
        // send it via POST instead of GET.
        if (JSON.stringify(this.query).split('').length > 2000) {
            this.options.method = 'POST';
        }
    }

    public send = (): Promise<ResultSet> => {
        // Transform handles converting our custom types/interfaces that have been built
        // on top of CubeJS types back to the correct format for the request.
        const transformedQuery = transformCubeQuery(this.query);
        return new CubeService().send(transformedQuery, this.options, this.loadOptions);
    };
}

export { CubeRequest };
